<template>
  <a
    href="https://isardvdi.com/"
    target="_blank"
  >
    <i18n
      path="views.login.powered-by"
      tag="p"
    >
      <template #isardvdi>
        <strong>IsardVDI</strong>
      </template>
    </i18n>
  </a>
</template>

<script>
export default {
}
</script>
