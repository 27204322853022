<template>
  <div class="border-0 mx-3 pb-2">
    <b-skeleton width="85%" />
    <b-skeleton width="55%" />
    <b-skeleton width="70%" />
    <br>
  </div>
</template>

<script>
export default {}
</script>
